.content-one-section{
    padding: 8.8rem 0;
    .container{
        .content-one__image{
            align-self: center;
        }
        div{
            figure{
                text-align: center;
            }
            ul{
                margin-left: 1.8rem;
            }
        }
    }
}
