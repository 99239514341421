.content-one-section {
  padding: 8.8rem 0;
}
.content-one-section .container .content-one__image {
  align-self: center;
}
.content-one-section .container div figure {
  text-align: center;
}
.content-one-section .container div ul {
  margin-left: 1.8rem;
}